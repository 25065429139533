/* eslint-disable react/prop-types */
import React from 'react';
import { Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export interface IControlProps {
  disabled: boolean;
  onSubmit(): void;
}

const Control = ({ disabled, onSubmit }: IControlProps) => {
  return (
    <Stack
      spacing={2}
      direction='row'
      sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
    >
      <Link to='/' style={{ textDecoration: 'none' }}>
        <Button variant='outlined'>AVBRYT</Button>
      </Link>
      <Button variant='contained' disabled={disabled} onClick={onSubmit}>
        SEND INN
      </Button>
    </Stack>
  );
};

export default Control;
