/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import ProjectEvaluationCard from './ProjectEvaluationCard/ProjectEvaluationCard';
import SupplierEvaluationCard from './SupplierEvaluationCard/SupplierEvaluationCard';
import EvaluationCardSkeleton from './EvaluationCard/EvaluationCardSkeleton';
import { useApi } from 'services/useApi/useApi';
import { IEvaluationGetModel } from 'services/useApi/models/IEvaluationGetModel';
import { toast } from 'react-toastify';
import { IPaginatedGetModel } from 'services/useApi/models/IPaginatedGetModel';
import EmptyStack from 'components/EvaluationCardStack/EmptyStack/EmptyStack';
import { log } from 'telemetry/logger';

interface EvaluationCardStackProps {
  pageSize?: number;
}

export const EvaluationCardStack: React.FC<EvaluationCardStackProps> = ({
  pageSize = 5,
}) => {
  const { getEvaluations } = useApi();
  const [evaluations, setEvaluations] = useState<
    IPaginatedGetModel<IEvaluationGetModel[]> | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    evaluationHandler(1);
  }, []);

  const evaluationHandler = async (page: number) => {
    try {
      setEvaluations(undefined);
      const response = await getEvaluations(pageSize, page);
      setEvaluations(response);
    } catch (error) {
      toast.error('Noe gikk galt!');
      log.error('Error fetching user evaluations', {
        page: page,
        pageSize: pageSize,
      });
    }
  };

  const paginationHandler = async (
    _: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrentPage(page);
    evaluationHandler(page);
  };

  const renderCard = (evaluation: IEvaluationGetModel, key: number) => {
    if (evaluation.evaluationType.name === 'Leverandør') {
      return (
        <SupplierEvaluationCard
          key={key}
          evalId={evaluation.id}
          projectName={evaluation.project.name}
          projectPhase={evaluation.projectPhase.name}
          supplierName={evaluation.supplier?.name ?? ''}
          supplierCategory={evaluation.supplierCategory?.name ?? ''}
          deadline={new Date(evaluation.evaluationEnd)}
          totalQuestions={evaluation.numberOfQuestions}
          answeredQuestions={evaluation.answeredQuestions}
        />
      );
    }

    if (evaluation.evaluationType.name === 'Prosjekt') {
      return (
        <ProjectEvaluationCard
          key={key}
          evalId={evaluation.id}
          projectName={evaluation.project.name}
          projectPhase={evaluation.projectPhase.name}
          deadline={new Date(evaluation.evaluationEnd)}
          totalQuestions={evaluation.numberOfQuestions}
          answeredQuestions={evaluation.answeredQuestions}
        />
      );
    }

    throw new Error(`Unknown evaluation type: ${evaluation.evaluationType}`);
  };

  const renderSkeleton = useCallback(() => {
    return [...Array(2)].map((_, i) => <EvaluationCardSkeleton key={i} />);
  }, []);

  const renderPagination = () => {
    if (
      evaluations !== undefined &&
      evaluations.data.length > 0 &&
      evaluations.totalPages !== undefined &&
      evaluations.totalPages > 1
    ) {
      return (
        <Pagination
          page={currentPage}
          count={evaluations?.totalPages ?? 1}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onChange={paginationHandler}
        />
      );
    }
  };

  const renderStack = () => {
    if (evaluations === undefined) return renderSkeleton();
    if (evaluations.data.length === 0) return <EmptyStack />;
    return evaluations.data.map((item, key) => renderCard(item, key));
  };

  return (
    <Stack spacing={2} sx={{ alignItems: 'center', overflow: 'auto' }}>
      {renderStack()}
      {renderPagination()}
    </Stack>
  );
};

export default EvaluationCardStack;
