import { AnswerValue } from 'services/useApi/models/AnswerValue';

interface IScale {
  value: AnswerValue;
  text: string;
}

export const scale: IScale[] = [
  {
    value: 1,
    text: 'Ikke gjennomført',
  },
  {
    value: 2,
    text: 'Avvik og dårlig',
  },
  {
    value: 3,
    text: 'Under forventet',
  },
  {
    value: 4,
    text: 'Som forventet',
  },
  {
    value: 5,
    text: 'Over forventet',
  },
  {
    value: -1,
    text: 'Ikke relevant',
  },
];
