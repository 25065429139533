/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import RatingDescription from './RatingDescription/RatingDescription';
import ProjectEvaluationSummary from './EvaluationSummary/ProjectEvaluationSummary';
import SupplierEvaluationSummary from './EvaluationSummary/SupplierEvaluationSummary';
import { Box, Stack } from '@mui/material';
import Question from './Question/Question';
import { useQuestionare } from './useQuestionare';
import QuestionareSkeleton from './QuestionareSkeleton';
import Comment from './Comment/Comment';
import Controls from './Controls/Controls';
import { constants } from './constants';

export interface QuestionareProps {
  evaluationId: number;
  onProgressChange?: (percentile: number) => void;
  forceTitleUpdate?: boolean;
}
export const Questionare = ({
  evaluationId,
  onProgressChange,
  forceTitleUpdate = true,
}: QuestionareProps): JSX.Element => {
  const {
    questions,
    toggleExpanded,
    updateAnswer,
    loading,
    evaluation,
    evaluationCompleted,
    onSubmit,
    updateComment,
  } = useQuestionare(evaluationId, onProgressChange);

  useEffect(() => {
    if (forceTitleUpdate && evaluation !== null) {
      let title = `Backe Evaluering: ${evaluation?.projectName}`;
      if (evaluation?.supplierName !== undefined) {
        title += ` - ${evaluation.supplierName}`;
      }

      document.title = title;
    }
  }, [evaluation]);

  return (
    <Box>
      {loading ? (
        <QuestionareSkeleton />
      ) : (
        <Stack spacing={2} alignItems='center'>
          <RatingDescription sx={{ display: { xs: 'none', xl: 'flex' } }} />
          <Stack spacing={2} sx={{ width: { xs: '100%', xl: '700px' } }}>
            {evaluation !== null && evaluation.type === 'Prosjekt' && (
              <ProjectEvaluationSummary
                projectName={evaluation.projectName}
                projectPhase={evaluation.projectPhase}
              />
            )}
            {evaluation !== null && evaluation.type === 'Leverandør' && (
              <SupplierEvaluationSummary
                projectName={evaluation.projectName}
                projectPhase={evaluation.projectPhase}
                supplierName={evaluation.supplierName}
                supplierCategory={evaluation.supplierCategoryName}
              />
            )}
            <RatingDescription sx={{ display: { xs: 'flex', xl: 'none' } }} />

            {questions.map((question, index) => (
              <Question
                key={question.id}
                question={question.question}
                answer={question.answer}
                expanded={question.expanded}
                onAccordionClick={() => toggleExpanded(index)}
                onAnswer={(answer) => updateAnswer(index, answer)}
              />
            ))}

            <Comment
              disclaimer={constants.commentDisclaimer}
              evaluationId={evaluationId}
              initialComment={evaluation?.comment ?? ''}
              onChange={updateComment}
            />
            <Controls disabled={!evaluationCompleted} onSubmit={onSubmit} />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default Questionare;
