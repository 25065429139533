/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Star } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import NotRelevantIcon from 'assets/icons/NotRelevantIcon/NotRelevantIcon';
import QuestionRating from './QuestionRating/QuestionRating';
import { AnswerValue } from 'services/useApi/models/AnswerValue';

export interface IQuestionProps extends React.HTMLProps<HTMLDivElement> {
  question: string;
  answer: AnswerValue | null;
  expanded: boolean;
  onAnswer: (answer: AnswerValue) => void;
  onAccordionClick: MouseEventHandler<HTMLDivElement>;
}

const Question = ({
  question,
  answer,
  expanded,
  onAnswer,
  onAccordionClick,
}: IQuestionProps) => {
  const element = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;

  useEffect(() => {
    scrollIntoView();
  }, [expanded]);

  const scrollIntoView = () => {
    if (element.current !== null && element.current !== undefined && expanded) {
      element.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const renderStars = useCallback(() => {
    switch (answer) {
      case null:
        return (
          <>
            <Star color='disabled' />
            <Typography>-</Typography>
          </>
        );
      case -1:
        return (
          <>
            <NotRelevantIcon />
            <Typography sx={{ visibility: 'hidden' }}>-</Typography>
          </>
        );
      default:
        return (
          <>
            <Star color='secondary' />
            <Typography>{answer}</Typography>
          </>
        );
    }
  }, [answer]);

  return (
    <Accordion square ref={element} expanded={expanded}>
      <AccordionSummary onClick={onAccordionClick}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ mr: 1 }}>{question}</Typography>
          {!expanded && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderStars()}
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} alignItems='center'>
          <QuestionRating value={answer} onChange={onAnswer} />
          <Typography variant='caption'>Sett din vurdering</Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default Question;
