/* eslint-disable react/prop-types */
import { Rating } from '@mui/material';
import React from 'react';
import { ReactComponent as NotRelevantIcon } from 'assets/icons/not-relevant.svg';
import { ReactComponent as NotRelevantEmptyIcon } from 'assets/icons/not-relevant-empty.svg';
import { SvgIcon } from '@mui/material';

export interface QuestionNotRelevantProps {
  relevant: boolean;
  onNotRelevant(): void;
}

export const QuestionNotRelevant: React.FC<QuestionNotRelevantProps> = ({
  relevant,
  onNotRelevant,
}) => {
  return (
    <Rating
      max={1}
      size='large'
      value={relevant ? 0 : 1}
      icon={
        <SvgIcon
          component={NotRelevantIcon}
          viewBox='-3 -3 24 24'
          sx={{ fontSize: 'inherit' }}
        />
      }
      emptyIcon={
        <SvgIcon
          component={NotRelevantEmptyIcon}
          viewBox='-3 -3 24 24'
          sx={{ color: 'disabled', fontSize: 'inherit' }}
        />
      }
      onChange={(_, value) => {
        if (value === 1 || value === null) {
          onNotRelevant();
        }
      }}
    />
  );
};

export default QuestionNotRelevant;
