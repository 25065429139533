import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import mainTheme from 'styles/mainTheme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Pages } from 'pages/Pages';
import { IPublicClientApplication } from '@azure/msal-browser';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { MsalProvider } from '@azure/msal-react';
import { ClientSideNavigation } from 'auth/ClientSideNavigation';

interface IAppProps {
  msalInstance: IPublicClientApplication;
  aiReactPlugin: ReactPlugin;
}

export const App = ({
  msalInstance,
  aiReactPlugin,
}: IAppProps): JSX.Element => {
  return (
    <AppInsightsContext.Provider value={aiReactPlugin}>
      <BrowserRouter>
        <ClientSideNavigation pca={msalInstance}>
          <MsalProvider instance={msalInstance}>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mainTheme}>
                <ToastContainer />
                <Pages />
              </ThemeProvider>
            </StyledEngineProvider>
          </MsalProvider>
        </ClientSideNavigation>
      </BrowserRouter>
    </AppInsightsContext.Provider>
  );
};
