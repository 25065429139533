import AuthLoadingPage from 'pages/AuthLoadingPage';
import { EvaluationPage } from 'pages/EvaluationPage';
import { HomePage } from 'pages/HomePage';
import LogoutPage from 'pages/LogoutPage';
import { Page404 } from 'pages/Page404';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import RouteGuard from 'auth/RouteGuard';
import LoginPage from 'pages/LoginPage';
import Page401 from 'pages/Page401';

export const Pages = (): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <RouteGuard
            loadingComponent={AuthLoadingPage}
            authenticationErrorComponent={Page401}
          >
            <HomePage />
          </RouteGuard>
        }
      />
      <Route
        path='/evaluations/:evaluationId'
        element={
          <RouteGuard
            loadingComponent={AuthLoadingPage}
            authenticationErrorComponent={Page401}
          >
            <EvaluationPage />
          </RouteGuard>
        }
      />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route path='/auth' element={<AuthLoadingPage />} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
};
