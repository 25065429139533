/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosRequestConfig } from 'axios';
import appSettings from 'config/appSettings';
import { log } from 'telemetry/logger';
import { useEffect, useState } from 'react';

export interface IUseSpaUpdate {
  updateAvailable: boolean;
  latestBuild: string;
}

interface IReleaseGetModel {
  buildNumber: string;
}

const axiosConfig: AxiosRequestConfig = {
  baseURL: `/`,
  responseType: 'json',
};

const getLatestRelease = async (): Promise<IReleaseGetModel> => {
  const response = await axios.get<IReleaseGetModel>(`/release.json`, {
    ...axiosConfig,
    method: 'GET',
  });

  return response.data;
};

export const useSpaUpdate = (): IUseSpaUpdate => {
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false);
  const [latestBuild, setLatestBuild] = useState<string>(
    appSettings.build.buildNumber,
  );

  const checkUpdate = async () => {
    try {
      const release = await getLatestRelease();
      if (release.buildNumber !== appSettings.build.buildNumber) {
        setLatestBuild(release.buildNumber);
        setUpdateAvailable(true);
      }
    } catch (error) {
      log.info('Unable to check for SPA update', { error });
      return false;
    }
  };

  useEffect(() => {
    checkUpdate();
    const intervalId = setInterval(() => {
      if (!updateAvailable) {
        checkUpdate();
      } else {
        clearInterval(intervalId);
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return {
    updateAvailable,
    latestBuild,
  };
};
