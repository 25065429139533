/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import EvaluationSummary from './EvaluationSummary';

interface IProjectEvaluationSummaryProps
  extends React.HTMLProps<HTMLDivElement> {
  projectName: string;
  projectPhase: string;
}

export const ProjectEvaluationSummary = ({
  projectName,
  projectPhase,
}: IProjectEvaluationSummaryProps): JSX.Element => {
  return (
    <EvaluationSummary variant='project' projectName={projectName}>
      <Box sx={{ display: 'inline-flex' }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left' sx={{ border: 0, padding: 0, pr: 1 }}>
                  Prosjektfase:
                </TableCell>
                <TableCell align='left' sx={{ border: 0, padding: 0 }}>
                  {projectPhase}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </EvaluationSummary>
  );
};

export default ProjectEvaluationSummary;
