/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as WaveSvg } from 'assets/icons/wave.svg';
import { PageLayout } from 'components';
import { useNavigate } from 'react-router-dom';

/**
 * @description This is just a landing
 * page after logout to prevent user
 * being redirected again to login.
 *
 * The route must be added to redirect urls
 * of the App Registration in Azure. logoutRedirectUrl
 * must also be configured in Msal.
 */
export const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <PageLayout title='Backe Evaluering: Logget ut'>
      <Stack spacing={2} sx={{ alignItems: 'center', px: { xs: 1, sm: 0 } }}>
        <SvgIcon
          sx={{
            width: { xs: '100%', sm: '292px' },
            height: { xs: '100%', sm: '351px' },
          }}
          component={WaveSvg}
          viewBox='0 0 292 351'
        />
        <Typography variant='body1' align='center' sx={{ fontWeight: 700 }}>
          Du er nå logget ut!
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            navigate('/login');
          }}
        >
          LOGG INN PÅ NYTT
        </Button>
      </Stack>
    </PageLayout>
  );
};

export default LogoutPage;
