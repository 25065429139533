import React, { PropsWithChildren } from 'react';
import appSettings from 'config/appSettings';
import {
  InteractionStatus,
  InteractionType,
  RedirectRequest,
} from '@azure/msal-browser';
import {
  IMsalContext,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  useMsal,
} from '@azure/msal-react';

export interface RouteGuardProps {
  loadingComponent: React.ElementType<IMsalContext> | React.ElementType;
  authenticationErrorComponent:
    | React.ElementType<MsalAuthenticationResult>
    | React.ElementType;
}

export const RouteGuard = ({
  loadingComponent,
  authenticationErrorComponent,
  children,
}: PropsWithChildren<RouteGuardProps>): React.ReactElement | null => {
  const { instance, inProgress } = useMsal();
  const LoadingComponent = loadingComponent;

  const getLoginRequest = (): RedirectRequest => {
    return {
      authority: appSettings.auth.authority,
      scopes: appSettings.auth.loginScopes,
      account: instance.getActiveAccount() ?? undefined,
      redirectUri: appSettings.auth.redirectUrl,
    };
  };

  return inProgress === InteractionStatus.None ? (
    <MsalAuthenticationTemplate
      interactionType={
        !!instance.getActiveAccount()
          ? InteractionType.Silent
          : InteractionType.Redirect
      }
      homeAccountId={instance.getActiveAccount()?.homeAccountId}
      authenticationRequest={getLoginRequest()}
      loadingComponent={loadingComponent as React.ElementType<IMsalContext>}
      errorComponent={
        authenticationErrorComponent as React.ElementType<MsalAuthenticationResult>
      }
    >
      {children}
    </MsalAuthenticationTemplate>
  ) : (
    <LoadingComponent />
  );
};

export default RouteGuard;
