import { createTheme } from '@mui/material/styles';
import { backe } from 'styles/jsColors';
import { responsiveFontSizes } from '@mui/material';

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const mainTheme = createTheme({
  palette: {
    primary: {
      main: backe.blue,
    },
    secondary: {
      main: backe.yellow,
    },
    background: {
      default: backe.gray,
    },
    text: {
      primary: backe.black,
      secondary: backe.white,
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: '"Arial", "Roboto", "Helvetica", sans-serif',
  },
});

export default responsiveFontSizes(mainTheme);
