/* eslint-disable react/prop-types */
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import QuestionRating from './QuestionRating';
import { scale } from './ratingScale';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IRatingDescriptionProps extends React.HTMLProps<HTMLDivElement> {
  sx?: SxProps<Theme>;
}

export const RatingDescription = ({
  sx,
}: IRatingDescriptionProps): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', ...sx }}>
      {/* Small screens */}
      <Box
        sx={{
          display: { xs: 'flex', xl: 'none' },
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Accordion square>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='body2'>VURDERINGSSKALA</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'inline-flex' }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {scale.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell
                          align='left'
                          sx={{ border: 0, padding: 0, pr: 1, width: '50%' }}
                        >
                          <QuestionRating
                            value={row.value}
                            size='medium'
                            showEmptyStars
                          />
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{ border: 0, padding: 0, width: '50%' }}
                        >
                          {row.text}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Large screens */}
      <Paper sx={{ display: { xs: 'none', xl: 'inline-flex' }, padding: 1 }}>
        <Stack spacing={1}>
          <Typography align='left' noWrap>
            VURDERINGSSKALA
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', xl: 'flex' },
              flexWrap: 'nowrap',
              alignItems: 'center',
            }}
          >
            {scale.map((row, key) => (
              <React.Fragment key={key}>
                <QuestionRating
                  value={row.value}
                  size='medium'
                  showEmptyStars={false}
                />
                <Typography variant='body2' align='left' noWrap sx={{ pr: 2 }}>
                  {row.text}
                </Typography>
              </React.Fragment>
            ))}
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default RatingDescription;
