/* eslint-disable react/prop-types */
import React from 'react';
import { Rating, Box } from '@mui/material';
import QuestionNotRelevant from './QuestionNotRelevant/QuestionNotRelevant';
import { AnswerValue } from 'services/useApi/models/AnswerValue';
import StarIcon from '@mui/icons-material/Star';

export interface QuestionRatingProps {
  value: AnswerValue | null;
  onChange(value: AnswerValue): void;
}

export const QuestionRating: React.FC<QuestionRatingProps> = ({
  value,
  onChange,
}) => {
  return (
    <Box
      sx={{
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Rating
        max={5}
        size='large'
        value={value !== null ? value : 0}
        onChange={(_, newValue) => {
          if (newValue === null && value !== null) {
            onChange(value);
          }
          if (newValue !== null && newValue >= 1 && newValue <= 5) {
            onChange(newValue as AnswerValue);
          }
        }}
        emptyIcon={<StarIcon sx={{ color: 'disabled' }} fontSize='inherit' />}
      />
      <QuestionNotRelevant
        relevant={value === -1 ? false : true}
        onNotRelevant={() => onChange(-1)}
      />
    </Box>
  );
};

QuestionRating.defaultProps = {
  value: null,
};

export default QuestionRating;
