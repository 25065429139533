/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  LinearProgress,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { useSpaUpdate } from './useSpaUpdate';
import React, { useEffect } from 'react';
import PageHeader from './PageHeader';
import SidebarMenu from './SidebarMenu';
import { backe } from 'styles/jsColors';
import { AuthenticatedTemplate } from '@azure/msal-react';

type PageLayoutProps = {
  title?: string;
  progress?: number;
  children?: React.ReactNode;
};

export const PageLayout = ({
  title = 'Backe Evaluering',
  children,
  progress,
}: PageLayoutProps): JSX.Element => {
  const { updateAvailable } = useSpaUpdate();
  const theme = useTheme();

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        px: { xs: 0 },
        display: 'grid',
        gridTemplateColumns: { xs: '0 1fr 0', lg: '200px 1fr 200px' },
        gridTemplateRows: 'min-content 1fr',
        gap: '0px 0px',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {/* Topbar */}
      <Box
        sx={{
          gridArea: { xs: '1 / 1 / 1 / 4', lg: '1 / 1 / 2 / 3' },
          pl: { xs: 1, lg: 2 },
          pr: { xs: 1, lg: 0 },
        }}
      >
        <PageHeader />
      </Box>

      {/* Sidebar */}
      <Box
        sx={{
          gridArea: '2 / 1 / 3 / 2',
          display: { xs: 'none', lg: 'block' },
          px: 2,
        }}
      >
        <AuthenticatedTemplate>
          <SidebarMenu />
        </AuthenticatedTemplate>
      </Box>

      {/* Padding left */}
      <Box
        sx={{
          gridArea: '1 / 1 / 3 / 2',
          display: { xs: 'none', lg: 'block' },
        }}
      ></Box>

      {/* Padding right */}
      <Box
        sx={{
          gridArea: '1 / 3 / 3 / 4',
          display: { xs: 'none', xl: 'block' },
        }}
      ></Box>

      {/* Content */}
      <Box
        sx={{
          gridArea: { xs: '2 / 1 / 2 / 4', lg: '2 / 2 / 3 / 3' },
          backgroundColor: theme.palette.background.default,
        }}
      >
        {/* Progress */}
        {progress !== undefined && (
          <Box
            sx={{
              position: { xs: 'sticky' },
              top: { xs: 0 },
              zIndex: { xs: 100 },
            }}
          >
            <LinearProgress
              value={progress}
              variant='determinate'
              color='secondary'
              sx={{
                bgcolor: 'primary.light',
              }}
            />
          </Box>
        )}

        {/* App update */}
        {updateAvailable && (
          <Link href='/' underline='none'>
            <Box
              sx={{
                position: { xs: 'sticky' },
                top: { xs: 0 },
                zIndex: { xs: 100 },
                py: 2,
                backgroundColor: backe.blueGray,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                variant='body1'
                color={theme.palette.text.primary}
                sx={{ textAlign: 'center' }}
              >
                🎉 En ny versjon er tilgjengelig, trykk her for å oppdatere 🎉
              </Typography>
            </Box>
          </Link>
        )}

        <Box
          sx={{
            px: { md: 2 },
            py: { xs: 3, md: 7 },
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </Container>
  );
};

export default PageLayout;
