/* eslint-disable react-hooks/exhaustive-deps */
import { AnswerValue } from 'services/useApi/models/AnswerValue';
import { EvaluationType } from 'services/useApi/models/EvaluationType';
import { useApi } from 'services/useApi/useApi';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import { log } from 'telemetry/logger';

interface IQuestion {
  id: number;
  question: string;
  answer: AnswerValue | null;
  expanded: boolean;
}

interface IEvaluation {
  type: EvaluationType;
  projectName: string;
  projectPhase: string;
  supplierName?: string;
  supplierCategoryName?: string;
  comment: string;
}

export interface IUseQuestionare {
  evaluation: IEvaluation | null;
  questions: IQuestion[];
  toggleExpanded: (questionId: number) => void;
  updateAnswer: (questionId: number, answer: AnswerValue) => void;
  loading: boolean;
  evaluationCompleted: boolean;
  onSubmit(): Promise<void>;
  updateComment(comment: string): void;
}

const isEvaluationCompleted = (questions: IQuestion[]): boolean => {
  const answers = questions.filter(
    (question) => question.answer !== null,
  ).length;
  return questions.length === answers;
};

export const useQuestionare = (
  evaluationId: number,
  onProgressChange?: (percentile: number) => void,
): IUseQuestionare => {
  const navigate = useNavigate();
  const { getQuestions, getEvaluation, closeEvaluation, upsertAnswer } =
    useApi();
  const [questions, setQuestions] = useImmer<IQuestion[]>([]);
  const [evaluation, setEvaluation] = useState<IEvaluation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [evaluationCompleted, setEvaluationCompleted] =
    useState<boolean>(false);
  const comment = useRef<string>();

  /**
   * @description Loading initial data
   */
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const evaluationData = await getEvaluation(evaluationId);
      const questionData = await getQuestions(evaluationId);

      setEvaluation({
        type: evaluationData.evaluationType.name,
        projectName: evaluationData.project.name,
        projectPhase: evaluationData.projectPhase.name,
        supplierName: evaluationData.supplier?.name,
        supplierCategoryName: evaluationData.supplierCategory?.name,
        comment: evaluationData.comment,
      });

      setQuestions(
        questionData.map((question) => ({
          id: question.id,
          question: question.value,
          answer: question.answer?.value ?? null,
          expanded: false,
        })),
      );

      toggleExpanded(0);
      setLoading(false);
    } catch (error) {
      toast.error('Klarte ikke å hente evaluering!');
      log.error('Unable to fetch evaluation', { evaluationId, error });
      navigate('/');
    }
  };

  const updateComment = (newComment: string) => {
    comment.current = newComment;
  };

  /**
   * @description Pass progress to context so we may
   * show this wherever we want.
   */
  useEffect(() => {
    if (onProgressChange !== undefined) {
      const sumAnswers = questions.filter(
        (question) => question.answer !== null,
      ).length;
      const sumQuestions = questions.length;

      if (sumAnswers > 0 && sumQuestions > 0) {
        const progress = (sumAnswers / sumQuestions) * 100;
        onProgressChange(progress);
      }
    }
  }, [questions]);

  /**
   * @description Toggle submit button
   */
  useEffect(() => {
    setEvaluationCompleted(isEvaluationCompleted(questions));
  }, [questions]);

  /**
   * @description This is where we update the answer.
   * Sideaffects are that we expand the next question
   * and scroll into view.
   * @param questionIndex Index of question
   * @param answer Answer value
   */
  const updateAnswer = async (questionIndex: number, answer: AnswerValue) => {
    setQuestions((draft) => {
      draft[questionIndex].answer = answer;
    });

    toggleExpanded(questionIndex + 1);

    try {
      await upsertAnswer(evaluationId, questions[questionIndex].id, {
        value: answer,
      });
    } catch (error) {
      log.error('Unable to update answer', {
        evaluationId,
        questionId: questions[questionIndex].id,
        error,
      });
      toast.error('Klarte ikke å lagre svar!');
    }
  };

  /**
   * @description This will toggle a question as expanded/closed.
   * All other questions will be closed.
   * @param questionIndex Index of question to toggle
   */
  const toggleExpanded = (questionIndex: number) => {
    setQuestions((draft) => {
      draft.forEach((question, index) => {
        if (index === questionIndex) {
          question.expanded = !question.expanded;
        } else if (question.expanded) {
          question.expanded = false;
        }
      });
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await closeEvaluation(evaluationId, comment.current ?? '');
      toast.success('Takk for at du svarte på evalueringen!');
      navigate('/');
    } catch {
      log.error('Unable to submit evaluatoin', { evaluationId });
      toast.error('Klarte ikke å sende inn besvarelse!');
    }
  };

  return {
    loading,
    evaluation,
    questions,
    toggleExpanded,
    updateAnswer,
    evaluationCompleted,
    onSubmit,
    updateComment,
  };
};
