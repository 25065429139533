/* eslint-disable react/prop-types */
import React from 'react';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as EmptyStackSvg } from './emptystack.svg';

export const EmptyStack: React.FC = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center', px: { xs: 1, sm: 0 } }}>
      <SvgIcon
        sx={{
          width: { xs: '100%', sm: '252px' },
          height: { xs: '100%', sm: '314px' },
        }}
        component={EmptyStackSvg}
        viewBox='0 0 252 314'
      />
      <Typography variant='body1' align='center' sx={{ fontWeight: 700 }}>
        Du har fullført alle dine evalueringer!
      </Typography>
    </Stack>
  );
};

export default EmptyStack;
