import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const SidebarMenu = (): JSX.Element => {
  const theme = useTheme();

  return (
    <MenuList>
      <Link to='/'>
        <MenuItem sx={{ px: 0 }} disableRipple>
          <ListItemIcon style={{ minWidth: '30px' }}>
            <AssignmentIcon
              fontSize='small'
              htmlColor={theme.palette.text.secondary}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant='body1'
              color='text.secondary'
              sx={{ textDecoration: 'underline' }}
            >
              Mine evalueringer
            </Typography>
          </ListItemText>
        </MenuItem>
      </Link>
    </MenuList>
  );
};

export default SidebarMenu;
