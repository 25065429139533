/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export const QuestionareSkeleton: React.FC = () => {
  return (
    <Stack spacing={2} alignItems='stretch' alignContent='center'>
      <Box
        sx={{
          width: '100%',
          maxWidth: { md: 625 },
          alignSelf: 'center',
        }}
      >
        <Skeleton variant='text' sx={{ py: 2 }} />
        <Skeleton variant='rectangular' height={118} />
      </Box>
      {[...Array(5)].map((_, i) => (
        <Box
          sx={{
            width: '100%',
            maxWidth: { md: 625 },
            alignSelf: 'center',
          }}
          key={i}
        >
          <Skeleton variant='text' sx={{ py: 2 }} />
        </Box>
      ))}
    </Stack>
  );
};

export default QuestionareSkeleton;
