/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EvaluationCardHeader, {
  EvaluationCardHeaderVariant,
} from './EvaluationCardHeader';

interface EvaluationCardProps {
  evalId: number;
  title: string;
  deadline: Date;
  totalQuestions: number;
  answeredQuestions: number;
  headerVariant: EvaluationCardHeaderVariant;
  children?: React.ReactNode;
}

export const EvaluationCard: React.FC<EvaluationCardProps> = ({
  evalId,
  title,
  deadline,
  totalQuestions,
  answeredQuestions,
  headerVariant,
  children,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const renderXsCard = useCallback(() => {
    return (
      <Box sx={{ width: '100%' }}>
        <EvaluationCardHeader variant={headerVariant} deadline={deadline} />
        <Card sx={{ width: '100%' }}>
          <CardContent sx={{ display: 'flex' }}>
            <Stack sx={{ width: '100%' }}>
              <Typography variant='caption' sx={{ alignSelf: 'flex-end' }}>
                {`${answeredQuestions}/${totalQuestions}`} besvart
              </Typography>
              <Typography
                sx={{ minWidth: '0', fontWeight: 700 }}
                noWrap
                variant='body1'
              >
                {title.toUpperCase()}
              </Typography>
              <Box sx={{ display: 'flex' }}>{children}</Box>
              <Box sx={{ alignSelf: 'flex-end', mt: 1 }}>
                <Link
                  to={`/evaluations/${evalId}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    sx={{
                      color: theme.palette.text.secondary,
                      bgcolor: theme.palette.primary.main,
                      ':hover': {
                        bgcolor: theme.palette.primary.main,
                      },
                    }}
                  >
                    BESVAR
                  </Button>
                </Link>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    );
  }, [isXs]);

  const renderSmCard = useCallback(() => {
    return (
      <Box sx={{ width: { sm: 450, md: 625 } }}>
        <EvaluationCardHeader variant={headerVariant} deadline={deadline} />
        <Card>
          <CardContent sx={{ display: 'flex' }}>
            <Box sx={{ minWidth: '20px', mr: 1 }}></Box>
            <Box
              sx={{
                mr: 1,
                minWidth: '0',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{ minWidth: '0', fontWeight: 700 }}
                noWrap
                variant='body1'
              >
                {title.toUpperCase()}
              </Typography>
              <Box sx={{ display: 'flex' }}>{children}</Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography variant='caption'>
                  {`${answeredQuestions}/${totalQuestions}`} besvart
                </Typography>
              </Box>
              <Box>
                <Link
                  to={`/evaluations/${evalId}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    sx={{
                      color: theme.palette.text.secondary,
                      bgcolor: theme.palette.primary.main,
                      ':hover': {
                        bgcolor: theme.palette.primary.main,
                      },
                    }}
                  >
                    BESVAR
                  </Button>
                </Link>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }, [isXs]);

  return isXs ? renderXsCard() : renderSmCard();
};

export default EvaluationCard;
