/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import EvaluationSummary from './EvaluationSummary';

interface ISupplierEvaluationSummaryProps
  extends React.HTMLProps<HTMLDivElement> {
  projectName: string;
  projectPhase: string;
  supplierName?: string;
  supplierCategory?: string;
}

export const SupplierEvaluationSummary = ({
  projectName,
  projectPhase,
  supplierName,
  supplierCategory,
}: ISupplierEvaluationSummaryProps): JSX.Element => {
  return (
    <EvaluationSummary variant='supplier' projectName={projectName}>
      <Box>
        <Typography variant='body1' sx={{ fontWeight: 700 }}>
          {supplierName}
        </Typography>
        <Box sx={{ display: 'inline-flex' }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align='left' sx={{ border: 0, padding: 0, pr: 1 }}>
                    Prosjektfase:
                  </TableCell>
                  <TableCell align='left' sx={{ border: 0, padding: 0 }}>
                    {projectPhase}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' sx={{ border: 0, padding: 0, pr: 1 }}>
                    Kategori:
                  </TableCell>
                  <TableCell align='left' sx={{ border: 0, padding: 0 }}>
                    {supplierCategory}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </EvaluationSummary>
  );
};

export default SupplierEvaluationSummary;
