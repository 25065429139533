/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from 'react';
import { PageLayout } from 'components';
import ReactLoading from 'react-loading';
import { Box } from '@mui/material';

/**
 * @description This is the page that
 * the user will be redirected to after
 * authenticating (redirect) with Azure AD.
 * It's important that this Page is unprotected
 * and doesn't trigger any Msal behaviour.
 *
 * When the user is redirected here, Msal will
 * load and pickup the auth code fragment hash
 * which it will use to fetch id-, refresh- and
 * accesstokens.
 */
export const AuthLoadingPage: React.FC = () => {
  return (
    <PageLayout title='Backe Evaluering: Logger inn'>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ReactLoading type='spin' height={150} width={150} />
      </Box>
    </PageLayout>
  );
};

export default AuthLoadingPage;
