import React from 'react';
import { PageLayout, EvaluationCardStack } from 'components';

export const HomePage = (): JSX.Element => {
  return (
    <PageLayout title='Backe Evaluering: Hjem'>
      <EvaluationCardStack />
    </PageLayout>
  );
};
