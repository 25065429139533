/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { instanceFactory } from './auth/msalInstanceFactory';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { App } from './App';
import { initializeApplicationInsights } from 'telemetry/appInsights';

const reactPlugin = initializeApplicationInsights();
const msalInstance = instanceFactory();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App msalInstance={msalInstance} aiReactPlugin={reactPlugin} />);
