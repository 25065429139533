/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const EvaluationCardSkeleton: React.FC = () => {
  return (
    <Box sx={{ minWidth: { xs: '100%', sm: 450, md: 625 } }}>
      <Skeleton variant='text' sx={{ py: 2 }} />
      <Skeleton variant='rectangular' height={118} />
    </Box>
  );
};

export default EvaluationCardSkeleton;
