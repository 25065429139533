/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

interface IEvaluationSummaryProps extends React.HTMLProps<HTMLDivElement> {
  variant: 'project' | 'supplier';
  projectName: string;
}

export const EvaluationSummary = ({
  variant,
  projectName,
  ...props
}: IEvaluationSummaryProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Paper>
      <Stack>
        <Box
          bgcolor={theme.palette.primary.main}
          sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
        >
          <ListItemIcon
            style={{ minWidth: '20px' }}
            sx={{ mr: 1, verticalAlign: 'middle' }}
          >
            {variant === 'project' ? (
              <ConstructionOutlinedIcon
                fontSize='small'
                htmlColor={theme.palette.text.secondary}
              />
            ) : (
              <SettingsOutlinedIcon
                fontSize='small'
                htmlColor={theme.palette.text.secondary}
              />
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography variant='body2' color='text.secondary'>
              {variant === 'project'
                ? `PROSJEKTEVALUERING - ${projectName.toUpperCase()}`
                : `LEVERANDØREVALUERING - ${projectName.toUpperCase()}`}
            </Typography>
          </ListItemText>
        </Box>
        <Box sx={{ padding: 2 }}>{props?.children}</Box>
      </Stack>
    </Paper>
  );
};

EvaluationSummary.defaultProps = {
  showHeader: true,
};

export default EvaluationSummary;
