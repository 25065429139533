/* eslint-disable react/prop-types */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from '@mui/material';
import EvaluationCard from '../EvaluationCard/EvaluationCard';

interface ProjectEvaluationCardProps {
  evalId: number;
  projectName: string;
  projectPhase: string;
  deadline: Date;
  totalQuestions: number;
  answeredQuestions: number;
}

export const ProjectEvaluationCard: React.FC<ProjectEvaluationCardProps> = ({
  evalId,
  projectName,
  projectPhase,
  deadline,
  totalQuestions,
  answeredQuestions,
}) => {
  return (
    <EvaluationCard
      headerVariant='project'
      evalId={evalId}
      title={projectName}
      deadline={deadline}
      totalQuestions={totalQuestions}
      answeredQuestions={answeredQuestions}
    >
      <Table
        size='small'
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            p: 0,
            verticalAlign: 'baseline',
          },
          [`& .${tableCellClasses.root}.key`]: {
            pr: 1,
            whiteSpace: 'nowrap',
            verticalAlign: 'bottom',
          },
          [`& .${tableCellClasses.root}.value`]: {
            width: '100%',
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell className='key'>
              <Typography variant='body2'>Prosjektfase:</Typography>
            </TableCell>
            <TableCell className='value'>
              <Typography variant='body2'>{projectPhase}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='key'>
              <Typography variant='body2'>Siste frist:</Typography>
            </TableCell>
            <TableCell className='value'>
              <Typography variant='body2'>
                {deadline.toLocaleDateString('nb-NO')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='key'>
              <Typography variant='body2'>Status:</Typography>
            </TableCell>
            <TableCell className='value'>
              <Typography variant='body2'>
                {`${answeredQuestions} av ${totalQuestions} spørsmål besvart`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </EvaluationCard>
  );
};

export default ProjectEvaluationCard;
