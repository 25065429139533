import axios, { ResponseType } from 'axios';
import { appSettings } from '../../config/appSettings';
import { IEvaluationGetModel } from './models/IEvaluationGetModel';
import { IAnswerPutModel } from './models/IAnswerPutModel';
import { IQuestionGetModel } from './models/IQuestionGetModel';
import { IPaginatedGetModel } from 'services/useApi/models/IPaginatedGetModel';
import { useMsalUtils } from 'auth/useMsalUtils';

export interface IUseApi {
  getEvaluations(
    pageSize?: number,
    page?: number,
  ): Promise<IPaginatedGetModel<IEvaluationGetModel[]>>;
  getEvaluation(evalId: string | number): Promise<IEvaluationGetModel>;
  getQuestions(evalId: string | number): Promise<IQuestionGetModel[]>;
  upsertAnswer(
    evalId: string | number,
    questionId: number,
    answer: IAnswerPutModel,
  ): Promise<void>;
  upsertComment(evalId: string | number, comment: string | null): Promise<void>;
  closeEvaluation(
    evalId: string | number,
    comment: string | null,
  ): Promise<void>;
}

interface IAxiosConfig {
  baseURL: string;
  responseType: ResponseType;
}

const axiosConfig: IAxiosConfig = {
  baseURL: `${appSettings.api.host}/v1`,
  responseType: 'json',
};

export const useApi = (): IUseApi => {
  const { getAccesstoken } = useMsalUtils();

  const getEvaluations = async (
    pageSize = 5,
    page = 1,
  ): Promise<IPaginatedGetModel<IEvaluationGetModel[]>> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<IPaginatedGetModel<IEvaluationGetModel[]>>(
      `/evaluations?pageSize=${pageSize}&pageNumber=${page}`,
      {
        ...axiosConfig,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  };

  const getEvaluation = async (
    evalId: string | number,
  ): Promise<IEvaluationGetModel> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<IEvaluationGetModel>(
      `/evaluations/${evalId}`,
      {
        ...axiosConfig,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  };

  const getQuestions = async (evalId: string): Promise<IQuestionGetModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<IQuestionGetModel[]>(
      `/evaluations/${evalId}/questions`,
      {
        ...axiosConfig,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  };

  /**
   * @description Creates or updates answer
   * @param evalId evaluation id
   * @param questionId question id
   * @param answer answer
   * @returns stuff
   */
  const upsertAnswer = async (
    evalId: string | number,
    questionId: number,
    answer: IAnswerPutModel,
  ): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.put(
      `/evaluations/${evalId}/questions/${questionId}/answer`,
      answer,
      {
        ...axiosConfig,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const closeEvaluation = async (
    evalId: string | number,
    comment: string | null = null,
  ): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.post(
      `/evaluations/${evalId}/answers/lock`,
      { comment },
      {
        ...axiosConfig,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const upsertComment = async (
    evalId: string | number,
    comment: string | null,
  ): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.put(
      `/evaluations/${evalId}/comment`,
      { comment },
      {
        ...axiosConfig,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };

  return {
    getEvaluations,
    getEvaluation,
    getQuestions,
    upsertAnswer,
    upsertComment,
    closeEvaluation,
  };
};
