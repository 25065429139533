/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface DeadlineCountdownProps {
  deadline: Date;
}

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const dateDiffInDays = (deadline: Date) => {
  // Discard the time and time-zone information.
  const now = new Date();
  const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const utc2 = Date.UTC(
    deadline.getFullYear(),
    deadline.getMonth(),
    deadline.getDate(),
  );

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const DeadlineCountdown: React.FC<DeadlineCountdownProps> = ({
  deadline,
}) => {
  const daysToDeadline = useMemo(() => {
    const deadlineDate = new Date(deadline);
    return dateDiffInDays(deadlineDate);
  }, [deadline]);

  const color = useMemo(() => {
    if (daysToDeadline <= 3) {
      return 'error';
    } else if (daysToDeadline <= 7) {
      return 'warning';
    } else {
      return 'success';
    }
  }, [daysToDeadline]);

  const text = useMemo(() => {
    if (daysToDeadline <= 0) {
      return 'UTLØPER I DAG';
    } else if (daysToDeadline === 1) return 'UTLØPER I MORGEN';
    else {
      return `${daysToDeadline} DAGER IGJEN`;
    }
  }, [daysToDeadline]);

  return (
    <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
      <ListItemText>
        <Typography variant='body2' color='text.secondary' align='right'>
          {text}
        </Typography>
      </ListItemText>
      <ListItemIcon style={{ minWidth: '20px' }} sx={{ ml: 1 }}>
        <CalendarMonthIcon fontSize='small' color={color} />
      </ListItemIcon>
    </Box>
  );
};

export default DeadlineCountdown;
