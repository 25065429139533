/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { Box, Rating } from '@mui/material';
import NotRelevantIcon from 'assets/icons/NotRelevantIcon/NotRelevantIcon';
import { AnswerValue } from 'services/useApi/models/AnswerValue';
import StarIcon from '@mui/icons-material/Star';

export interface QuestionRatingProps {
  value: AnswerValue;
  size: 'small' | 'medium' | 'large';
  showEmptyStars: boolean;
}

export const QuestionRating: React.FC<QuestionRatingProps> = ({
  value = 1,
  size = 'medium',
  showEmptyStars = true,
}) => {
  const getMax = useCallback(
    () => (showEmptyStars ? 5 : value),
    [showEmptyStars, value],
  );
  return (
    <Box>
      {value === -1 ? (
        <Rating
          max={1}
          size={size}
          value={1}
          icon={<NotRelevantIcon />}
          readOnly
        />
      ) : (
        <Rating
          max={getMax()}
          size={size}
          value={value}
          readOnly
          emptyIcon={<StarIcon sx={{ color: 'disabled' }} fontSize='inherit' />}
        />
      )}
    </Box>
  );
};

QuestionRating.defaultProps = {
  value: 1,
  size: 'medium',
};

export default QuestionRating;
