/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeadlineCountdown from './DeadlineCountdown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

export type EvaluationCardHeaderVariant = 'project' | 'supplier';

interface EvaluationCardHeaderProps {
  variant: EvaluationCardHeaderVariant;
  deadline: Date;
}

export const EvaluationCardHeader: React.FC<EvaluationCardHeaderProps> = ({
  variant,
  deadline,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only('xs'));

  const getHeaderTitle = useCallback(() => {
    if (variant === 'project') {
      return matches ? 'PROSJEKT-EVALUERING' : 'PROSJEKTEVALUERING';
    } else {
      return matches ? 'LEVERANDØR-EVALUERING' : 'LEVERANDØREVALUERING';
    }
  }, [matches]);

  return (
    <Box
      bgcolor={'primary.main'}
      sx={{ py: 1, display: 'flex', justifyContent: 'space-between' }}
    >
      <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
        <ListItemIcon style={{ minWidth: '20px' }} sx={{ mr: 1 }}>
          {variant === 'project' ? (
            <ConstructionOutlinedIcon
              fontSize='small'
              htmlColor={theme.palette.text.secondary}
            />
          ) : (
            <SettingsOutlinedIcon
              fontSize='small'
              htmlColor={theme.palette.text.secondary}
            />
          )}
        </ListItemIcon>
        <ListItemText>
          <Typography variant='body2' color='text.secondary'>
            {getHeaderTitle()}
          </Typography>
        </ListItemText>
      </Box>
      <Box>
        <DeadlineCountdown deadline={deadline} />
      </Box>
    </Box>
  );
};

export default EvaluationCardHeader;
