import axios, { ResponseType } from 'axios';
import { log } from 'telemetry/logger';
import { useMsalUtils } from 'auth/useMsalUtils';

export interface IUseGraph {
  getProfilePictureUrl(): Promise<string>;
}

interface IAxiosConfig {
  baseURL: string;
  responseType: ResponseType;
}

const axiosConfig: IAxiosConfig = {
  baseURL: `https://graph.microsoft.com`,
  responseType: 'json',
};

let profilePicture: string | undefined = undefined;

export const useGraph = (): IUseGraph => {
  const { getAccesstoken } = useMsalUtils();

  const getProfilePictureUrlInternal = async (): Promise<string> => {
    const token = await getAccesstoken([
      'https://graph.microsoft.com/.default',
    ]);
    const response = await axios.get<Blob>(`/v1.0/me/photos/48x48/$value`, {
      ...axiosConfig,
      responseType: 'blob',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'image/jpg',
      },
    });

    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response.data);
    return imageUrl;
  };

  const getProfilePictureUrl = async (): Promise<string> => {
    if (profilePicture !== undefined) return profilePicture;

    try {
      profilePicture = await getProfilePictureUrlInternal();
    } catch (error) {
      profilePicture = '';
      log.debug(
        'Unable to fetch profile picture for user. This would happen if the user is a guest user',
      );
    }

    return profilePicture;
  };

  return {
    getProfilePictureUrl,
  };
};
