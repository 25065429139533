import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'auth/msalConfig';
import { addUserToApplicationInsights } from 'telemetry/appInsights';
import { log } from 'telemetry/logger';

/**
 * @description This is our instance factory for Msal.
 * There should only be one instance of Msal running,
 * so that's up to the caller to ensure. Make sure to
 * call this in your index.ts file before rendering react.
 *
 * We use this to to determine active account, either after
 * login or when no login has been triggered because the user
 * is already signed in.
 */
export const instanceFactory = (): PublicClientApplication => {
  const instance = new PublicClientApplication(msalConfig);

  const account = instance.getActiveAccount();
  if (!!account) {
    addUserToApplicationInsights(account.username);
  }

  if (!instance.getActiveAccount() && !!instance.getAllAccounts().length) {
    log.debug(
      'instanceFactory - no active account set, but there are existing accounts in cache. Setting first account as active',
    );
    instance.setActiveAccount(instance.getAllAccounts()[0]);
    addUserToApplicationInsights(instance.getAllAccounts()[0].username);
  }

  instance
    .handleRedirectPromise()
    .then((data) => {
      if (!!data?.account) {
        log.debug(
          'instanceFactory - a redirect was processed and result contains an account. Setting result account to active account',
        );
        instance.setActiveAccount(data.account);
        addUserToApplicationInsights(data.account.username);
      }
    })
    .catch();

  return instance;
};
