/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Stack,
  TextField,
  useTheme,
  Fade,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { log } from 'telemetry/logger';
import { toast } from 'react-toastify';
import { useApi } from 'services/useApi/useApi';

export interface ICommentProps {
  disclaimer: string;
  evaluationId: number;
  initialComment: string;
  onChange(comment: string): void;
}

const MAX_LENGTH = 1000;
const Comment = ({
  disclaimer,
  evaluationId,
  initialComment,
  onChange,
}: ICommentProps) => {
  const theme = useTheme();
  const { upsertComment } = useApi();
  const [comment, setComment] = useState<string>(initialComment);
  const [saving, setSaving] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (!firstRender) {
      if (comment !== null) {
        const timeOutId = setTimeout(() => {
          saveComment();
        }, 500);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [comment]);

  const saveComment = async () => {
    try {
      setSaving(true);
      await upsertComment(evaluationId, comment);
    } catch (error) {
      log.error('Unable to save comment', { evaluationId, error });
      toast.error('Klarte ikke å lagre kommentar!');
    } finally {
      setTimeout(() => {
        setSaving(false);
      }, 500);
    }
  };

  const handleComment: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    onChange(e.currentTarget.value);
    setComment(e.currentTarget.value);
  };

  return (
    <Stack bgcolor={theme.palette.common.white} sx={{ boxShadow: 1 }}>
      <Stack sx={{ p: 2 }} spacing={2}>
        <Typography>Kommentar:</Typography>
        <Typography variant='caption'>{disclaimer}</Typography>
        <TextField
          multiline
          rows={6}
          variant='outlined'
          fullWidth
          defaultValue={initialComment}
          onChange={handleComment}
          inputProps={{ maxLength: MAX_LENGTH }}
          helperText={`${comment.length}/${MAX_LENGTH}`}
          error={false}
          sx={{
            '.MuiFormHelperText-root': {
              color: 'black !important',
            },
          }}
        />
      </Stack>
      <Box
        bgcolor={theme.palette.background.default}
        sx={{ px: 2, display: 'flex', justifyContent: 'space-between' }}
      >
        <Fade in={saving}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <SaveIcon fontSize='small' sx={{ mr: 1 }} />
            <Typography variant='caption'>Autolagrer...</Typography>
          </Box>
        </Fade>
      </Box>
    </Stack>
  );
};

export default Comment;
