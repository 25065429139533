import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import BackeLogo from 'assets/logos/backe.png';
import ProfileMenu from 'components/PageLayout/ProfileMenu';
import { AuthenticatedTemplate } from '@azure/msal-react';

export const PageHeader = (): JSX.Element => {
  return (
    <AppBar position='static' elevation={0}>
      <Toolbar disableGutters>
        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'flex' },
          }}
        >
          <Link
            to='/'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={BackeLogo}
              alt='Backe logo'
              style={{
                maxHeight: '45px',
                width: 'auto',
                height: 'auto',
              }}
            />
          </Link>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 0, minWidth: 0 }}>
          <AuthenticatedTemplate>
            <ProfileMenu />
          </AuthenticatedTemplate>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeader;
