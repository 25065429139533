/* eslint-disable react/prop-types */
import { SvgIcon } from '@mui/material';
import React from 'react';
import { ReactComponent as NotRelevantSvg } from 'assets/icons/not-relevant.svg';

export const NotRelevantIcon: React.FC = () => {
  return <SvgIcon component={NotRelevantSvg} viewBox='-3 -3 24 24' />;
};

export default NotRelevantIcon;
