/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { PageLayout, Questionare } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const EvaluationPage = (): JSX.Element => {
  const { evaluationId } = useParams();
  const navigate = useNavigate();
  const [parsedId, setParsedId] = useState<number | undefined>(undefined);
  const [progress, setProgress] = useState<number>(0);

  const parseQuery = () => {
    if (parsedId === undefined) {
      if (
        evaluationId === undefined ||
        evaluationId === null ||
        isNaN(parseInt(evaluationId))
      ) {
        toast.error('Ugyldig evaluerings id.');
        navigate('/');
      } else {
        setParsedId(parseInt(evaluationId));
      }
    }
  };
  parseQuery();

  const handleProgress = (value: number) => {
    if (value !== progress) {
      setProgress(value);
    }
  };

  return (
    <PageLayout progress={progress}>
      {parsedId !== undefined && (
        <Questionare
          evaluationId={parsedId}
          onProgressChange={handleProgress}
        />
      )}
    </PageLayout>
  );
};
